<template>
	<div class="filter-sidebar">
		<b-sidebar
		id="sidebarFilters"
		right
		backdrop
		backdrop-variant="dark"
		sidebar-class="filter-side"
		no-header
		shadow
		v-model="sidebarVisible"
		>

		<b-form class="d-flex flex-column h100vh px-36 pb-32 pt-36">

			<div class="header d-flex align-items-center pb-40">
				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
				<h2 class="text-gray-900">Filters</h2>
				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
					<i class="icon-close-variant"></i>
				</b-button>
			</div>

			<b-input
			class="mb-17"
			title="Reference Number"
			id="referenceNumber"
			placeholder="Reference Number"
			v-model="form.referenceNo"
			@input="addFilter({referenceNo: form.referenceNo})"
			></b-input>

			<multiselect
			title="Transaction Type"
			id="type"
			v-model="form.type"
			:options="types"
			label="name"
			:searchable="false"
			:show-labels="false"
			placeholder="Transaction Type"
			class="mb-17"
			@input="addFilter({type: form.type})"
			></multiselect>

			<date-picker
			prefix-class="xmx"
			title="Transaction Date"
			v-model="form.date"
			id="dp date"
			class="mb-17"
			range
			value-type="format"
			format="YYYY-MM-DD"
			placeholder="Start Date - End Date"
			:editable="false"
			:disabled-date="(date) => date > new Date()"
			@input="addFilter({date: form.date})"
			></date-picker>

			<small v-if="daysFromDates() >= 30" class="text-danger">Only 30 days export is allowed.</small>
			<div class="footer mt-auto text-right pt-8">
				<b-link
				v-if="this.hasFilters"
				class="secondary fw500 mr-20"
				@click="clearFilters()"
				>
				Clear filters
			</b-link>
			<b-button
			:disabled="!this.hasFilters"
			variant="primary"
			@click="applyFilter"
			>
			Apply filter
		</b-button>
	</div>
</b-form>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
	name: "FilterPassbook",
	data() {
		return {
			sidebarVisible: false,
			selectedFilter: 1,
			typeSelected: null,
			type: null,
			form: {},
			types: [
			{name: "Opening Balance", value:"OPB"},
			{name: "Payment", value:"PMT"},
			{name: "Credit", value:"CRN"},
			{name: "TDS", value:"TDS"},
			{name: "Shipping Charge", value:"SCG"},
			{name: "Shipping Charge Refund", value:"SCR"},
			{name: "Invoice Charges", value:"ICG"},
			{name: "Invoice Charges Refund", value:"ICR"},
			{name: "Withdrawal", value:"WTH"}
			],
			selectedFilters: this.$store.getters.filters.passbook || {},
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	
	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
	},

	created() {

		let form = Object.assign({}, this.$store.getters.filters.passbook)
		if(this.selectedFilters.dateStart) {
			form.date = [this.selectedFilters.dateStart, this.selectedFilters.dateEnd]
		}
		delete form.dateStart
		delete form.dateEnd

		this.form = form
	},

	methods: {

		applyFilter(event) {

			if(this.selectedFilters.date) {
				this.selectedFilters.dateStart = this.selectedFilters.date[0]
				this.selectedFilters.dateEnd = this.selectedFilters.date[1]
			} else {
				delete this.selectedFilters.dateStart
				delete this.selectedFilters.dateEnd
			}
			delete this.selectedFilters.date

			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'passbook': this.selectedFilters})
			this.$parent.getFilters()

		},

		daysFromDates() {
			if(this.selectedFilters.date) {
				const date1 = new Date(this.selectedFilters.date[0]);
				const date2 = new Date(this.selectedFilters.date[1]);
				const diffTime = Math.abs(date2 - date1);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
				return diffDays
			}
		},

		addFilter(filter) {
			if((!filter.date && Object.values(filter)[0]) || (filter.date && Object.values(filter)[0][0])) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				let temp = this.selectedFilters
				if(filter.date) {
					delete temp.dateStart
					delete temp.dateEnd
				} else {
					delete temp[Object.keys(filter)[0]]
				}
				this.selectedFilters = Object.assign({}, temp)
				if(Object.keys(temp)==0) {
					this.clearFilters()
				}
			}
		},
		clearFilters(parent) {
			let filter = this.form
			this.form = {}
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(!parent) {
				this.$parent.clearFilters();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.ndr {
	margin-top: -65px;
	background: #eff2f5;
}
.filter-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		top: rem(86px);
		right: rem(20px);
		z-index: 10;
		button {
			i {
				margin: 0 !important;
				padding: 0 !important;
			}
			span {
				display: none;
			}
		}
		.b-sidebar-body {
			height: calc(100% - 86px);
		}
	}

	&.billing-sidebar {
		position: relative !important;
		top: 0;
		right: 0;
		z-index: 8;
	}
}
</style>