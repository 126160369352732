var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-sm" }, [
    _c("div", { staticClass: "card-sm__header" }, [_vm._t("header")], 2),
    _c(
      "div",
      { staticClass: "card-sm__content" },
      [
        _c("div", { staticClass: "icon-wrap fs20", class: this.iconColor }, [
          _c("i", { class: this.iconClass })
        ]),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }