import { render, staticRenderFns } from "./ResultNotFound.vue?vue&type=template&id=b38f4048&"
import script from "./ResultNotFound.vue?vue&type=script&lang=js&"
export * from "./ResultNotFound.vue?vue&type=script&lang=js&"
import style0 from "./ResultNotFound.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/slv/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b38f4048')) {
      api.createRecord('b38f4048', component.options)
    } else {
      api.reload('b38f4048', component.options)
    }
    module.hot.accept("./ResultNotFound.vue?vue&type=template&id=b38f4048&", function () {
      api.rerender('b38f4048', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ResultNotFound.vue"
export default component.exports