<template>
  <div class="container-fluid position-relative">
    <div v-if="!filterResultsToggle && this.windowWidth > 767" class="d-flex align-items-center justify-content-end action-buttons pb-20 filter-margin">
      <div class="hbreak d-none d-md-inline-flex"></div>      
      <div class="divider mx-20 vam d-none d-md-flex"></div>

      <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
        <span>
          Filters
        </span>
        <i class="icon-filter text-gray-600 pl-10"></i>
      </b-button>

    </div>

    <div v-if="!filterResultsToggle && this.windowWidth < 767" class="d-flex align-items-center justify-content-end action-buttons-mobile pb-36 filter-margin button-settings">
      <div class="hbreak d-none d-md-inline-flex"></div>      
      <div class="divider mx-20 vam d-none d-md-flex"></div>

      <b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
        <span>
        </span>
        <i class="icon-filter text-gray-600"></i>
      </b-button>
    </div>

    <transition name="slide-right">
      <div class="d-flex select-bar passbook" v-if="filterResultsToggle">
        <div class="align-items-center container-fluid d-flex p-0 pb-20">
          <div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0" >
            <div class="d-flex align-items-center">
              <span class="d-none d-md-flex mr-6 text-gray-600 wsnw" v-if="this.selFilters.length != '0'">
                Results for:
              </span>
              <ul class="d-none d-md-flex result-list">
                <li v-for="(selFilter, index) in selFilters" :key="index">
                  <b-button class="mx-4 my-1" variant="tag" v-if="selFilter && index!='filtering'">
                    <span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
                      {{ selFilter.name }}
                    </span>
                    <span v-else class="wsnw text-truncate">
                      {{ selFilter }}
                    </span>
                    <i class="icon-close fs6" @click="removeFilter(index)"></i>
                  </b-button>
                </li>
              </ul>

              <span class="d-flex d-md-none" v-if="this.selFilters.length != '0'">
                {{ this.selFilters.length }} Filters selected.
              </span>
              <span class="d-flex d-md-none" v-else>
                No filters selected.
              </span>
              <b-link @click="clearFilters()" class="ml-24 mr-12 vam">
                <span class="text-gray-900 wsnw">Clear filters</span>
                <i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
              </b-link>
            </div>
            <div class="d-flex align-items-center">
              <b-button @click="passbookExport" variant="secondary" v-if="(selFilters.dateEnd && daysFromDates()<=30) && items.length>0">
                <i class="icon-download fs16"></i>
                <span class="d-md-inlineml-md-10 ml-6">
                  Export
                </span>
              </b-button>
              <div class="divider mx-20 vam d-none d-md-flex"></div>
              <b-button variant="secondary" class="text-gray-900 d-none d-md-flex ml-8" v-b-toggle.sidebarFilters>
                <span class="d-none d-md-flex">
                  Filters
                </span>
                <i class="icon-filter text-gray-600 pl-0 pl-md-10"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="this.windowWidth > 767">
      <b-table
      :items="items"
      :fields="fields"
      :per-page="perPage"
      class="billing-table"
      :busy="isBusy"
      sticky-header
      responsive
      no-border-collapse
      >

      <!-- Table Spinner element -->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <!-- A custom formatted data column cell for field 'transactionDate' -->
      <template v-slot:cell(transactionDate)="data">
        <span> {{ data.item.transactionDate }}</span>
      </template>

      <!-- A custom formatted data column cell for field 'referenceNo' -->
      <template v-slot:cell(referenceNo)="data">
        <span v-b-tooltip.hover.left
              :title="data.item.type == 'Invoice Charges Refund' || data.item.type == 'Invoice Charges' ? 'Invoice number' : data.item.type == 'Shipping Charge' || data.item.type == 'Shipping Charge Refund' ? 'Tracking Number' : '' "> {{ data.item.referenceNo }}</span>
      </template>

      <!-- A custom formatted data column cell for field 'Amount' -->
      <template v-slot:cell(amount)="data">
        <span>{{ data.item.CRDR }}</span>
      </template>

      <!-- A custom formatted data column cell for field 'Closing_due' -->
      <template v-slot:cell(due)="data">
        <span>{{ data.item.due }}</span>
      </template>

      <!-- A custom formatted data column cell for field 'Transaction Type' -->
      <template v-slot:cell(type)="data">
        <span>{{ data.item.type }}</span>
      </template>

      <!-- A custom formatted data column cell for field 'Payment Channel' -->
      <template v-slot:cell(channel)="data">
        <span>{{ data.item.channel }}</span>
      </template>

      </b-table>
    </div>

    <!-- passbook mobile view -->

    <ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
      <li class="" v-for="item in items" :key="item.id">
        <div class="d-flex w-100 align-items-end justify-content-between">
          <span class="d-block fw500 pb-8 cp">
            Transaction Date:
            {{ item.transactionDate }}
          </span>
        </div>
        <div class="d-flex w-100 justify-content-between flex-column">

          <div class="d-flex justify-content-between">
            <div class="m-4 d-flex flex-column">
              <span class="fs12 lh16 text-gray-600">
                Reference No
              </span>
              <span>
                {{ item.referenceNo }}
              </span>
            </div>

            <div class="m-4 d-flex flex-column align-items-end">
              <span class="fs12 lh16 text-gray-600">
                Amount (₹)
              </span>
              <span>
                {{ item.CRDR}}
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <div class="m-4 d-flex flex-column">
              <span class="fs12 lh16 text-gray-600">
                Closing Balance (₹)
              </span>
              <span>
                {{ item.due}}
              </span>
            </div>

            <div class="m-4 d-flex flex-column align-items-end">
              <span class="fs12 lh16 text-gray-600">
                Transaction Type
              </span>
              <span>
                {{ item.type}}
              </span>
            </div>
          </div>

          <div class="m-4 d-flex flex-column">
            <span class="fs12 lh16 text-gray-600">
              Payment Channel
            </span>
            <span>
              {{ item.channel}}
            </span>
          </div>
        </div>
      </li>
    </ul>

  <div class="pagination-bar">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <div class="d-inline-flex wsnw align-items-center">
            <div class="d-block w50px">
              <multiselect
              class="pagination-items__pp"
              v-model="perPage"
              :searchable="false"
              :show-labels="false"
              :allow-empty="false"
              :options="pageOptions"
              @input="setPageNumber"
              ></multiselect>
            </div>
            <span
            class="pl-8 fs12 lh24 text-gray-600 pagination-items"
            ></span>
          </div>
        </div>
        <div class="col-8 text-right">
          <div class="d-inline-flex ml-auto wsnw align-items-center">
            <p class="fs12 lh24 text-gray-600 pr-14">
              Page: {{ currentPage }}
            </p>
            <b-button-group>
              <b-button
              @click="prevPage()"
              :disabled="prevDisabled"
              variant="paginate left"
              >
              <i class="icon-back fs12"></i>
            </b-button>

            <b-button
            @click="nextPage()"
            :disabled="nextDisabled"
            variant="paginate right"
            >
            <i class="icon-arrow fs12"></i>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </div>
</div>
</div>
<FilterPassbook ref="passbookFilter" class="pl-20 pl-lg-0" />
<ResultNotFound v-if="items.length == 0 && !isBusy" />
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import FilterPassbook from "@/components/FilterPassbook.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";


export default {
  name: "BillingPassbook",
  props: ["activetabValue"],
  data() {
    return {
      isBusy:false,
      time3: null,
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: 1,
      perPage: this.$store.state.perPage,
      totalPages: 1,
      prevDisabled: true,
      selFilters: this.$store.getters.filters.passbook || {},
      filterResultsToggle: this.$store.getters.filters.passbook ? true : false,
      nextDisabled: false,
      startPage: 1,
      pagesArr: [],
      pagination: {},
      items:[],
      fields: [
      {
        key: "transactionDate",
        label: "Transaction Date",
        thClass: "wsnw",
      },
      {
        key: "referenceNo",
        label: "Reference Number",
      },
      {
        key: "amount",
        label: "Amount (₹)",
      },
      {
        key: "due",
        label: "Closing Balance (₹)",
        tdClass: "wsnw",
      },
      {
        key: "type",
        label: "Transaction Type",
      },
      {
        key: "channel",
        label: "Payment Channel",
      },
      ],
    };
  },
  components: {
    DatePicker,
    Multiselect,
    FilterPassbook,
    ResultNotFound
  },
  mounted() {
    this.totalRows = this.items.length;
    this.totalPages = Math.ceil(this.totalRows / this.perPage);
    for (var i = 1; i <= this.totalPages; i++) {
      this.pagesArr.push(i);
    }

    this.$root.$on("eventing", () => {
      this.openCancelModal;
    });

    this.getPassbook();
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    async getPassbook(params) {
      // try {
        this.isBusy = true;

        if(!params) {
          let params = {}
        }
        if(Object.keys(this.selFilters).length > 0) {
          params = {...{filtering: true}, ...this.selFilters}
          if(params.type) {
            params.type = params.type.value;
          }
        }

        const response = await this.axios.get(`v3/billing/passbook?page=${this.currentPage}&offset=${this.perPage}`, {params: params})
        this.items = response.data.passbook;
        this.totalRows = this.items.length
        this.isBusy = false;
        this.sendData();
        this.pagination = response.data.pagination;
        this.setPagination()

        if(this.items.length < this.perPage) {
          this.nextDisabled = true
        }

      // }
      // catch(error) {
      //   this.isBusy = false
      //   this.popToast("failed","Failed!","Data Fetching Failed!! Please Try again Later");
      // }
    },
    updatePageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    daysFromDates() {
      const date1 = new Date(this.selFilters.dateStart);
      const date2 = new Date(this.selFilters.dateEnd);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      return diffDays
    },
    async passbookExport() {

      let params = {
        dateStart: this.selFilters.dateStart,
        dateEnd: this.selFilters.dateEnd,
        type:this.selFilters.type ? this.selFilters.type.value : ''
      }

      try {
        let result = await this.axios.get('/v3/export/billing/passbook', {params: params})

        if (result.data.passbookExport.success == true) {
          this.popToast("booked", "Success", result.data.passbookExport.message);
        }
      }
      catch(err){
        console.log(err)
      }
      

    },
    getFilters() {
      this.currentPage = 1
      this.filterResultsToggle = true;
      this.selFilters = this.$store.getters.filters.passbook
      this.getPassbook()
      this.$emit('receivePassbookData', this.totalRows)
    },
    removeFilter(index) {
      console.log(index)
      const selFilters = {...this.selFilters}
      if(index == 'dateEnd' || index == 'dateStart') {
        delete selFilters.dateEnd
        delete selFilters.dateStart
        delete this.$refs.passbookFilter.selectedFilters.dateEnd
        delete this.$refs.passbookFilter.selectedFilters.dateStart
        delete this.$refs.passbookFilter.form.date
      }else{
        delete selFilters[index]
        delete this.$refs.passbookFilter.selectedFilters[index]
      }
      this.selFilters = selFilters
      this.$store.commit('setFilter', {'passbook': selFilters})
      delete this.$refs.passbookFilter.form[index]
      if(Object.keys(this.selFilters).length > 0) {
        this.getPassbook()
      }else{
        this.clearFilters()
      }
      this.$emit('receivePassbookData', this.totalRows)
    },
    clearFilters() {
      this.filterResultsToggle = false;
      this.selFilters = {};
      this.$refs.passbookFilter.clearFilters(true)
      this.getPassbook({filtering: false})
      this.$emit('receivePassbookData', this.totalRows)
    },
    sendData() {
      this.$emit('receivePassbookData', this.totalRows)
    },
    nextPage() {
      this.currentPage++;
      this.getPassbook();
    },
    prevPage() {
      this.currentPage--;
      this.getPassbook();
    },
    applyFilter() {
      this.filterResultsToggle = true;
    },
    // removeFilter(item) {
    //   this.selFilters.splice(item.id, 1);
    // },
    setPagination() {
      this.nextDisabled = this.pagination?.next ? false : true
      this.prevDisabled = this.pagination?.prev ? false : true
    },
    setPageNumber() {
      this.currentPage = 1
      this.getPassbook();
      
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];

      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }

      this.prevDisabled = true;

      if (this.totalPages == 1) {
        this.nextDisabled = true;
      } 
      else if (this.totalPages > 1) {
        this.nextDisabled = false;
      }
    },
    downloadOrders() {
      this.popToast(
        "requested",
        "Download requested",
        "Your download should start now."
        );
      this.filterResultsToggle = false;
    },
  },
  watch: {
    currentPage: function() {
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentPage == 1) {
        this.prevDisabled = true;
      }

      if (this.currentPage == this.totalPages) {
        this.nextDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .billing-table {
    min-height: unset !important;
    max-height: 430px !important; 
  }
  .b-table-top-row{
    background-color: #fff;
    border-top: 0.125rem solid #f5f7fa;
    border-bottom: 0.125rem solid #f5f7fa;
    line-height: 1.25rem;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    transition: all 0.2s ease-in-out;
    transition-delay: 0s;
  }
  .filter-margin {
    margin-top: -72px;
  }
  .action-buttons-mobile {
    position: absolute;
    right: 20px;
  }

  .action-buttons {
      width: 100px;
      float: right;
  }

  .no-results {
    position: fixed;
    top: 60%;
    right: calc(50% - 96px);
    z-index: -1;
  }

</style>