<template>
  <div class="container-fluid position-relative">

    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :busy="isBusy"
      class="billing-table"
      responsive
      sticky-header
      no-border-collapse
    >
      <!-- Table Spinner element -->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <!-- A custom formatted data column cell for head field 'status' -->
      <template v-slot:head(status)="">
        <span> Status </span>
      </template>
      <!-- A custom formatted data column cell for field 'type' -->
      <template v-slot:cell(type)="data">
        <span class="value-type">
          {{ data.item.type }}
        </span>
      </template>

      <!-- A custom formatted data column cell for field 'transaction_date' -->
      <template v-slot:cell(minimumRechargeAmount)="data">
        <span> {{ `&#8377${data.item.minimumRechargeAmount}` }}</span>
      </template>

      <!-- A custom formatted data column cell for field 'amount_inv' -->
      <template v-slot:cell(amount_inv)="data">
        <span>{{data.item.amount_inv != '-' ? `${data.item.amount_inv}` : '-' }}</span>
      </template>
      <!-- A custom formatted data column cell for field 'amount' -->
      <template v-slot:cell(amount)="data">
        <span>{{data.item.amount != '-' ? `${data.item.amount}` : '-' }}</span>
      </template>
      <!-- A custom formatted data column cell for field 'closing_due' -->
      <template v-slot:cell(closing_due)="data">
        <span>{{data.item.closing_due != '' ? `${data.item.closing_due}` : '-' }}</span>
      </template>
      <!-- A custom formatted data column cell for field 'Status' -->
      <template v-slot:cell(status)="data">
        <div class="d-flex justify-content-center align-items-center">
          <template v-if="data.item.typesort == 'INV' || data.item.typesort == 'INVI'">
            <b-button variant="primary btn-xs" :disabled="data.item.buttonDisable" v-if="data.item.buttonLabel == 'Dispute'" @click="goDispute(data.item.ref_number)"> {{data.item.buttonLabel}} </b-button>
            <b-button variant="primary btn-xs" @click="goExist(data.item.ref_number)" v-if="data.item.buttonLabel == 'Exist'"> {{data.item.buttonLabel}}  </b-button>
          </template>
          <template>
            <span class="tag" v-if="!(data.item.typesort == 'INV' || data.item.typesort == 'INVI')" :class="data.item.status">
              {{ data.item.status | capitalize }}
            </span>
          </template>
        </div>
      </template>
      <!-- A custom formatted data column cell for field 'Download' -->
      <template v-slot:cell(download)="data">
        <div class="d-flex justify-content-center align-items-center">
          <template v-if="(data.item.typesort == 'INV' || data.item.typesort == 'INVI') && !data.item.ref_number.includes('SLR') && data.item.latestDate">
            <b-button
              @click="downloadToast(data.item.ref_number, 'pdf')"
              size="xs"
              variant="icon-transparent-gray-800 px-6"
              v-b-tooltip.hover.right
              title="Download PDF"
            >
              <i class="icon-export-pdf fs20"></i>
            </b-button>
            <b-button
              @click="downloadToast(data.item.ref_number, 'excel')"
              size="xs"
              variant="icon-transparent-gray-800 px-6"
              v-b-tooltip.hover.right
              title="Download XLS"
            >
              <i class="icon-export-xls fs20"></i>
            </b-button>
          </template>
        </div>
      </template>
    </b-table>

    <ul class="table-cards d-flex d-md-none">
      <li class="" v-for="item in items" :key="item.id">
        <div class="d-flex align-items-center justify-content-between">
          <span class="d-block fw500 pb-8">
            Transaction
            {{ item.ref_number }}
          </span>
          <template v-if="item.typesort == 'INV' || item.typesort == 'INVI'">
            <b-button variant="primary btn-xs" :disabled="item.buttonDisable" v-if="item.buttonLabel == 'Dispute'" @click="goDispute(item.ref_number)"> {{item.buttonLabel}} </b-button>
            <b-button variant="primary btn-xs" @click="goExist(item.ref_number)" v-if="item.buttonLabel == 'Exist'"> {{item.buttonLabel}}  </b-button>
          </template>
          <template v-if="item.type == 'Payment'">
            <span class="tag" :class="item.status">
              {{ item.status | capitalize }}
            </span>
          </template>
        </div>
        <div class="d-flex">
          <div class="info">
            <span class="value-type pl-0 mb-4">
              {{ item.type }}
            </span>

            <span class="carrier-mode mb-4">
              Due date: {{ item.inv_due_date }}
            </span>
            <span class="carrier-mode mb-4">
              Transaction date: {{ item.transaction_date | date }}
            </span>
          </div>
        </div>
        <div class="d-flex">
          <div class="price">
            <span class="d-block text-red mb-4" v-if="item.amount_inv">
              Due: ₹{{ item.amount_inv }}
            </span>
            <span v-else> n/a </span>
            <span class="d-block mb-4">Amount: ₹ {{ item.amount }}</span>
          </div>
        </div>
      </li>
    </ul>

    <div class="pagination-bar position-relative">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-4">
            <div class="d-inline-flex wsnw align-items-center">
              <div class="d-block w50px">
                <multiselect
                  class="pagination-items__pp"
                  v-model="perPage"
                  :searchable="false"
                  :show-labels="false"
                  :allow-empty="false"
                  :options="pageOptions"
                  @input="setPageNumber"
                ></multiselect>
              </div>
              <span
                class="pl-8 fs12 lh24 text-gray-600 pagination-items"
              ></span>
            </div>
          </div>
          <div class="col-8 text-right">
            <div class="d-inline-flex ml-auto wsnw align-items-center">
              <p class="fs12 lh24 text-gray-600 pr-14">
                {{ currentPage }} of {{ totalPages }} pages
              </p>

              <!-- commented if used later here or somewhere else -->

              <!-- <multiselect
                class="pagination-page mr-4"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                v-model="currentPage"
                :options="pagesArr"
                @input="updatePageNumber"
              ></multiselect> -->

              <b-button-group>
                <b-button
                  @click="prevPage()"
                  :disabled="prevDisabled"
                  variant="paginate left"
                >
                  <i class="icon-back fs12"></i>
                </b-button>

                <b-button
                  @click="nextPage()"
                  :disabled="nextDisabled"
                  variant="paginate right"
                >
                  <i class="icon-arrow fs12"></i>
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
  name: "RatecardTable",
  data() {
    return {
      isBusy: false,
      time3: null,
      unbilled: "",
      totBalance: "",
      usableBalance: "",
      filterResultsToggle: false,
      selFilters: [],
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: 1,
      perPage: this.$store.state.perPage,
      totalPages: 1,
      prevDisabled: true,
      nextDisabled: false,
      startPage: 1,
      pagesArr: [],
      items: [],
      lastInvoice:'',
      lastPayment:'',
      fields: [
        {
          key: "plan"
        },
        {
          key: "minimumRechargeAmount",
          label: "Minimum Recharge Amount"
        },
        {
          key: "activationDate",
          label: "Activation Date"
        },
        {
          key: "expiryDate",
          label: "Expiry Date"
        }
        
      ],
    };
  },
  components: {
    DatePicker,
    Multiselect,
  },
  created() {
    this.getTransactions();
  },
  mounted() {
    this.totalRows = this.items.length;
    this.totalPages = Math.ceil(this.totalRows / this.perPage);
    for (var i = 1; i <= this.totalPages; i++) {
      this.pagesArr.push(i);
    }

    this.$root.$on("eventing", () => {
      this.openCancelModal;
    });
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    async getTransactions() {
      try {
        this.isBusy = true;
        const response = await this.axios.get("/billing/plans");
        this.items = response.data.plans
        this.totalRows = this.items.length;
        this.setPageNumber();
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false
        this.popToast("failed","Failed!","Data Fetching Failed!! Please Try again Later");
      }
    },
    addZeroes(num) {
      const dec = num.split('.')[1]
      const len = dec && dec.length > 2 ? dec.length : 2
      const a = Number(num).toFixed(len)
      return a
    },
    updatePageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    setPageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
      this.currentPage = 1;
      this.prevDisabled = true;
      if (this.totalPages == 1) {
        this.nextDisabled = true;
      } else if (this.totalPages > 1) {
        this.nextDisabled = false;
      }
    },
    nextPage() {
      this.currentPage++;
    },
    prevPage() {
      this.currentPage--;
    }
  },
  watch: {
    currentPage: function () {
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentPage == 1) {
        this.prevDisabled = true;
      }

      if (this.currentPage == this.totalPages) {
        this.nextDisabled = true;
      }
    },
  },
};
</script>

<style type="text/css" scoped>
  .billing-table {
    min-height: unset !important;
    max-height: 370px !important; 
  }
  .pagination-bar {
    left: unset !important;
    right: unset !important;
    width: 100% !important;
    padding: 0rem 0 0.75rem 0 !important;
  }
</style>

