<template>
  <div class="billing">
    <AppTopbar
      title="Billing"
      buttonText="New Order"
      buttonLink="new"
      buttonIconClass="icon-add"
    ></AppTopbar>

    <div class="container-fluid position-relative pt-64 pt-md-90">
      <div class="card-wrapper">
        <Card iconColor="green" iconClass="icon-payment" v-if="this.$store.getters.user.prepaid == 0">
          <template v-slot:header>
            <span class="text-gray-600 lh24">
              Total <span v-if="user.prepaid == 1"> Balance </span><span v-else> Due </span>
            </span>
          </template>
          <slot>
            <span class="card-sm__text">
              {{totalTransaction | currency('inr')}}
            </span>
          </slot>
        </Card>

        <Card iconColor="red" iconClass="icon-loan" v-if="this.$store.getters.user.prepaid == 0">
          <template v-slot:header>
            <span class="text-gray-600 lh24">
              <span v-if="user.prepaid == 1">Unbilled Receivables</span><span v-else>Last Invoice Amount</span>
            </span>
          </template>
          <slot>
            <span class="card-sm__text" v-if="user.prepaid == 1">
              {{unbilledReceivables | currency('inr')}}
            </span>
            <span class="card-sm__text" v-else>
              {{lastINV | currency('inr')}}
            </span>
          </slot>
        </Card>

        <Card iconColor="blue" iconClass="icon-shipments" v-if="user.prepaid == 0">
          <template v-slot:header>
            <span class="text-gray-600 lh24">
              <span >Last Payment Amount</span>
            </span>
          </template>
          <slot>
            <span class="card-sm__text">
              {{lastPMT | currency('inr')}}
            </span>
          </slot>
        </Card>


        <!-- It will show when passbook tab display for the seller -->
        <Card iconColor="red" iconClass="icon-loan" v-if="this.$store.getters.user.prepaid == 1">
          <template v-slot:header>
            <span class="text-gray-600 lh24">
              <span>Usable balance</span>
            </span>
          </template>
          <slot>
            <span class="card-sm__text">
              {{passbookDue | currency('inr')}}
            </span>
          </slot>
        </Card>

        <Card v-if="showPlanTab" iconClass="icon-total_deliveries" iconColor="orange">
          <template v-slot:header>
            <span class="text-gray-600 lh24">
              Recharge Since {{currentPlan.activationDate}}
            </span>
            <!-- <span class="text-gray-400 lh24">
              <i class="icon-dropup text-green vam fs8 mr-10"></i>
              20%
            </span> -->
          </template>
          <slot>
            <span class="card-sm__text " v-if="currentPlan.totalRechargeAmount >= 0">
              {{currentPlan.totalRechargeAmount | currency('inr')}} <span class="card-text">{{currentPlan.minAmount != '₹0' ? `(Min. ${currentPlan.minAmount})` : '' }}</span> 
            </span>
            <span class="card-sm__text" v-else>0</span>
          </slot>
        </Card>
      </div>

      <div class="card-wrapper row pt-10 pt-md-20 pb-22 flex-nowrap billing_tabs w-100">
        <div class="col-md-auto">
          <ul class="filter-list billing pl-10" :class="{'filter-list-mobile': selected.title=='Passbook'}">
            <li
              v-for="tab in tabs"
              :key="tab.id"
              :class="[{ active: selected.id === tab.id }]"
            >
              <b-link @click="selected = tab" class="btn-filter wsnw">
                {{ tab.title }} <span class="ml-3" v-if="tab.title == 'Transactions' || tab.title == 'Invoices' && tab.title!=='Passbook'"></span>
              </b-link>
            </li>
          </ul>
        </div>
        <div class="col-md-auto ml-auto d-flex align-items-center justify-content-end " :class="{'widthStyle': selected.title=='Passbook'}" >
          <!-- <b-button v-if="this.$store.getters.user.prepaid == 1" @click="exportUnbilled" class="mr-10" variant="primary">
            <b-spinner v-if="unbilledspinning"></b-spinner>
            <i v-else class="icon-download mr-11"></i>
            Export Unbilled
          </b-button> -->

          <b-button
            @click="openAddFundsModal"
            variant="primary"
            class="btn-small d-none d-md-block"
          >
            <i class="icon-payment-method fs16 pr-8"></i>
            Add Funds
          </b-button>
        </div>
      </div>
    </div>

    <component :is="selectedTab.component"></component>

    <BillingAddFunds :selectedvariable="method" @openModal="openAddFundsModal" ref="BillingAddFunds" />
    <BillingTransactionsTable style="display:none" @receiveData="receiveData" />
    <BillingInvoicesTable style="display:none" @receiveBillingData="receiveBillingData" />

    <BillingPassbook style="display:none" :activetabValue="this.selected.id" ref="BillingPassbook" @receivePassbookData="receivePassbookData" />
    
  </div>
</template>

<script>
import Card from "@/components/dashboardCard.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import BillingAddFunds from "@/components/BillingAddFunds.vue";
import BillingInvoicesTable from "@/components/BillingInvoicesTable.vue";
import BillingPassbook from "@/components/BillingPassbook.vue";
import BillingTransactionsTable from "@/components/BillingTransactionsTable.vue";
import RatecardTable from "@/views/billing/RatecardTable.vue";
import { mapState } from "vuex";

export default {
  name: "Billing",
  title: "Shyplite - Billing",
  data() {
    return {
      method:'AddFundsMethod',
      unbilledReceivables:'',
      passbookDue: '',
      totalTransaction:'',
      usableBalance:'',
      activetabValue: '',
      lastINV: '',
      lastPMT:'',
      currentPlan: {},
      selected: {
          id: 1,
          component: "BillingInvoicesTable",
          title: "Invoices",
          amount: "0",      
      },
      plantabObject: {
          id: 3,
          component: "RatecardTable",
          title: "Plans",
          amount: "0",
      },
      tabs: [
        {
          id: 1,
          component: "BillingInvoicesTable",
          title: "Invoices",
          amount: "0",
        },
      ],
      showPlanTab:false,
      unbilledspinning : false
    };
  },
  components: {
    Card,
    AppTopbar,
    BillingAddFunds,
    BillingPassbook,
    BillingInvoicesTable,
    BillingTransactionsTable,
    RatecardTable
  },
  methods: {
    async exportUnbilled() {
      this.unbilledspinning = true
      
      try {
        const res = await this.axios.get('/unbilled/export')
        if (res.data.success) {
          this.popToast("requested", "Success!", "Your export is getting ready. We'll email you when it's done.");
        } else {
          this.popToast("failed", "Failed!", "Export Failed!! Please Try Again");
        }
      } catch(e) {
        this.popToast("failed", "Failed!", "Export Failed!! Please Try Again");
      }
      this.unbilledspinning = false
    },
    openAddFundsModal() {
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
    },
    receiveData({length, unbilled, total, usable, lastInv, lastPmt, current, showPlans, closingDue, passbookDue}) {
      this.tabs[0].amount = length
      this.unbilledReceivables = unbilled
      this.totalTransaction = total
      this.usableBalance = usable
      this.passbook_closingDue = closingDue
      this.passbookDue = passbookDue
      this.lastINV = parseFloat(lastInv)
      this.lastPMT = parseFloat(lastPmt)
      this.currentPlan = Object.assign({},current)
      let d = 0
      if(Number(this.currentPlan.minAmount) === this.currentPlan.minAmount && this.currentPlan.minAmount % 1 !== 0) {
          d = 2
      }
      
      if(this.currentPlan && this.currentPlan.minAmount) {
          this.currentPlan.minAmount = this.currentPlan.minAmount.toLocaleString('en-IN', {
            maximumFractionDigits: d,
            style: 'currency',
            currency: 'inr'
        })
      }
      
      if (showPlans) { 
        this.showPlanTab = true
        this.tabs.splice(2, 0, this.plantabObject)
      }
    },

    receiveBillingData(value) {
      this.tabs[1].amount = value
    },
    receivePassbookData(value) {
      // if(this.$store.getters.user.prepaid == 1 && Number(this.$store.getters.user.id) <= 25000) {
      //  this.tabs[2].amount = value
      // }
    },
    callback(status) {
      if (status.status == 'TXN_SUCCESS' || status.status == 'success'){
        this.popToast("booked", "Success!", `${status.amount} has been added successfully `)
      } else {
        this.popToast("failed", "Failed!", `Payment Processing Failed!! Please Try again later`)
      }
    },

  },
  async created(){
      let obj = {
          id: 0,
          component: "BillingTransactionsTable",
          title: "Transactions",
          amount: "0",
      }
      this.tabs.unshift(obj)
      this.selected = obj


    if(this.$route.query.status) {
      await this.callback(this.$route.query)
      this.$router.replace({'query': null});
    }

    if (this.$store.state.user.prepaid == 1) {
      const passbookobj = {
       id: 2,
       component: "BillingPassbook",
       title: "Passbook",
       amount: "0",
     }
     this.tabs.unshift(passbookobj)
     this.selected = passbookobj
    }

  },
  mounted() {
    return {
      selectedTab: function() {
        return this.selected;
      },
    };
  },
  computed: {
    selectedTab: function() {
      return this.selected;
    },
    ...mapState(["user"])
  },
};
</script>

<style lang="scss" scoped>
.action-buttons {
  position: fixed;
  top: rem(94px);
  right: rem(36px);
  @include media-breakpoint-only(md) {
    top: rem(90px);
    right: rem(20px);
  }
  @include media-breakpoint-down(sm) {
    z-index: 10;
    top: rem(86px);
    right: rem(20px);
  }
}
.toggle-sidebar {
  color: $gray-900;
  margin-left: rem(8px);
  @include media-breakpoint-down(sm) {
    position: fixed;
    z-index: 10;
    top: 5.375rem;
    right: 1.25rem;
  }
}
.card-sm__text {
  font-size: 1.5rem !important;
}
.card-text {
  font-size: 0.90rem;
  font-weight: 400 !important;
  color: gray;
}
.billing_tabs {
  overflow-y: hidden
}
@include media-breakpoint-down(sm) {
  .filter-list-mobile {
     max-width: 80%;
  }
}
.widthStyle {
    margin-right: 120px;
}
</style>