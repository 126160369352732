<template>
  <div class="container-fluid position-relative">
    <transition name="slide-right">
      <div class="select-bar d-flex billing" v-if="filterResultsToggle">
        <div class="container-fluid d-flex align-items-center">
          <div
            class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0"
          >
            <div class="d-flex align-items-center">
              <span
                class="d-none d-md-flex mr-6 text-gray-600 wsnw"
                v-if="this.selFilters.length != '0'"
              >
                Results for:
              </span>
              <ul class="d-none d-md-flex result-list">
                <li v-for="selFilter in selFilters" :key="selFilter.id">
                  <b-button class="mx-4 my-1" variant="tag">
                    <span class="wsnw">
                      {{ selFilter.filter }}
                    </span>
                    <i
                      class="icon-close fs6"
                      @click="removeFilter(selFilter.id)"
                    ></i>
                  </b-button>
                </li>
              </ul>

              <span
                class="d-flex d-md-none"
                v-if="this.selFilters.length != '0'"
              >
                {{ this.selFilters.length }} Filters selected.
              </span>
              <span class="d-flex d-md-none" v-else>
                No filters selected.
              </span>

      <b-link @click="clearFilters()" class="ml-24 mr-12 vam"><span class="text-gray-900 wsnw">Clear filters</span><i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i></b-link>
            </div>
            <div class="d-flex align-items-center">
              <b-button
                variant="secondary"
                class="wsnw"
                @click="downloadOrders"
              >
                <i class="icon-download fs16"></i>
                <span class="d-none d-md-inline ml-0 ml-md-10">
                  Download Orders
                </span>
              </b-button>
              <b-button
                variant="secondary"
                class="text-gray-900 ml-8 d-none d-md-flex"
                v-b-toggle.sidebarFilters
              >
                <span class="d-none d-md-flex">
                  Filters
                </span>
                <i class="icon-filter text-gray-600 pl-0 pl-md-10"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="this.windowWidth > 767">
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="billing-table"
        :busy="isBusy"
        sticky-header
        responsive
        no-border-collapse
        v-if="items.length > 0"
      >

        <!-- Table Spinner element -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <!-- A custom formatted data column cell for head field 'Status' -->
        <template v-slot:head(status)>
          <span>
            Status
          </span>
        </template>


        <!-- A custom formatted data column cell for field 'inv_due_date' -->
<!--         <template v-slot:cell(inv_due_date)="data">
          <span class="value-inv_due_date" :class="data.item.inv_due_date">
            {{ data.item.inv_due_date | date }}
          </span>
        </template> -->

        <!-- A custom formatted data column cell for field 'Type' -->
        <template v-slot:cell(type)="data">
          <span class="value-type" :class="data.item.type">
            {{ data.item.type }}
          </span>
        </template>

        <!-- A custom formatted data column cell for field 'transactionDate' -->
        <template v-slot:cell(transactionDate)="data">
          <span>{{ data.item.transactionDate | date }}</span>
        </template>

        <!-- A custom formatted data column cell for field 'Amount_inv' -->
        <template v-slot:cell(amount_inv)="data">
          <span>{{ data.item.amount_inv }}</span>
        </template>

        <!-- A custom formatted data column cell for field 'Amount' -->
        <template v-slot:cell(amount)="data">
          <span>{{ data.item.amount }}</span>
        </template>

        <!-- A custom formatted data column cell for field 'paidAmount' -->
        <template v-slot:cell(paidAmount)="data">
          <span>{{ data.item.paidAmount }}</span>
        </template>

        <template v-slot:cell(tds)="data">
          <span>{{ data.item.tdsAmount }}</span>
        </template>

        <template v-slot:cell(invduedate)="data">
          <span>{{ data.item.invoiceDate | date }}</span>
        </template>

        <template v-slot:cell(cn)="data">
          <span>{{ data.item.crnAmount }}</span>
        </template>

        <!-- A custom formatted data column cell for field 'dueAmount' -->
        <template v-slot:cell(dueAmount)="data">
          <span>{{ data.item.dueAmount }}</span>
        </template>

        <!-- A custom formatted data column cell for field 'Closing_due' -->
        <template v-slot:cell(closingDue)="data">
          <span>{{ data.item.closingDue }}</span>
        </template>

        <!-- A custom formatted data column cell for field 'Status' -->
        <template v-slot:cell(status)="data">
          <div class="d-flex justify-content-center align-items-center">
            <template v-if="data.item.status == 'dispute'">
              <b-button variant="primary btn-xs">
                Dispute
              </b-button>
            </template>
            <template v-else>
              <span class="tag" :class="data.item.status">
                {{ data.item.status | capitalize }}
              </span>
            </template>
          </div>
        </template>

        <!-- A custom formatted data column cell for field 'Download' -->
        <template v-slot:cell(download)="data">
          <div class="d-flex justify-content-center align-items-center">
            <template v-if="data.item.status == 'success'">
              <b-button variant="transparent btn-xs px-6">
                <i class="icon-export-pdf fs18"></i>
              </b-button>
              <b-button variant="transparent btn-xs px-6">
                <i class="icon-export-xls fs18"></i>
              </b-button>
            </template>
          </div>
        </template>


        <template v-slot:cell(dispute)="data">
          <div class="d-flex">

            <template v-if="data.item.type == 'INV' || data.item.type == 'INVI'">

              <b-button 
                variant="primary btn-xs" 
                :disabled="data.item.buttonDisable" 
                v-if="!(data.item.buttonLabel == 'Dispute' && $store.getters.user.prepaid == 1)" 
                @click="goDispute(data.item.refNo)"
              >
                {{data.item.buttonLabel}} 
              </b-button>

              <b-button 
                variant="primary btn-xs" 
                @click="goExist(data.item.ref_number)" 
                v-if="data.item.buttonLabel == 'Exist'"
              >
                {{data.item.buttonLabel}}
              </b-button>

            </template>

          </div>
        </template>

        <!-- A custom formatted data column cell for field 'Download' -->
        <template v-slot:cell(download)="data">
          <div class="d-flex">
            <template v-if="(data.item.type == 'INV' || data.item.type == 'INVI') && !data.item.refNo.includes('SLR') && data.item.latestDate">
              <b-button
                @click="downloadToast(data.item.refNo, 'pdf')"
                size="xs"
                variant="icon-transparent-gray-800 px-6"
                v-b-tooltip.hover.right
                title="Download PDF"
              >
                <i class="icon-export-pdf fs20"></i>
              </b-button>
              <b-button
                @click="downloadToast(data.item.refNo, 'excel')"
                size="xs"
                variant="icon-transparent-gray-800 px-6"
                v-b-tooltip.hover.right
                title="Download XLS"
              >
                <i class="icon-export-xls fs20"></i>
              </b-button>
            </template>
          </div>
        </template>

      </b-table>
      <div v-else>
        <div class="my-120">
            <div class="d-flex flex-column align-items-center">
              <i class="icon-empty-state text-blue pb-24 fs58"></i>
              <span class="fs14 fw500 pb-8 text-blue">
                Data not Available
              </span>
               <span class="fs12 pb-8 text-gray-600">
                There is no data to show you right now
              </span>
            </div>
          </div>
      </div>
    </div>

    <ul class="table-cards d-flex d-md-none">
        <li class="" v-for="item in items" :key="item.id">
            <div class="d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="d-block fw500 pb-4">
                        <strong>Invoice No: </strong> {{ item.refNo }}
                    </span>

                    <div v-if="item.typesort == 'INV' || item.typesort == 'INVI'">
                        <b-button variant="primary btn-xs" :disabled="item.buttonDisable" v-if="item.buttonLabel == 'Dispute'" @click="goDispute(item.refNo)"> {{item.buttonLabel}} </b-button>
                        <b-button variant="primary btn-xs" @click="goExist(item.ref_number)" v-if="item.buttonLabel == 'Exist'"> {{item.buttonLabel}}  </b-button>
                    </div>

                    <span class="tag" v-if="!(item.typesort == 'INV' || item.typesort == 'INVI')" :class="item.status">
                        {{ item.status | capitalize }}
                    </span>
                </div>

                <span class="mb-4">
                    <strong>Inv. Due Date: </strong> {{ item.invoiceDate | date }}
                </span>

                <span class="mb-4">
                    <strong>Transaction date: </strong> {{ item.transactionDate | date }}
                </span>

                <span class="mb-4">
                    <strong>Due Amount (₹): </strong> {{ item.dueAmount }}
                </span>
                
            </div>
        </li>
    </ul>

    <div class="pagination-bar position-relative" v-if="items.length > 0 ">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-4">
            <div class="d-inline-flex wsnw align-items-center">
              <div class="d-block w50px">
                <multiselect
                  class="pagination-items__pp"
                  v-model="perPage"
                  :searchable="false"
                  :show-labels="false"
                  :allow-empty="false"
                  :options="pageOptions"
                  @input="setPageNumber"
                ></multiselect>
              </div>
              <span
                class="pl-8 fs12 lh24 text-gray-600 pagination-items"
              ></span>
            </div>
          </div>
          <div class="col-8 text-right">
            <div class="d-inline-flex ml-auto wsnw align-items-center">
              <p class="fs12 lh24 text-gray-600 pr-14">
                {{ currentPage }} of {{ totalPages }} pages
              </p>

              <!-- commented if used later here or somewhere else -->
              
              <!-- <multiselect
                class="pagination-page mr-4"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                v-model="currentPage"
                :options="pagesArr"
                @input="updatePageNumber"
              ></multiselect> -->

              <b-button-group>
                <b-button
                  @click="prevPage()"
                  :disabled="prevDisabled"
                  variant="paginate left"
                >
                  <i class="icon-back fs12"></i>
                </b-button>

                <b-button
                  @click="nextPage()"
                  :disabled="nextDisabled"
                  variant="paginate right"
                >
                  <i class="icon-arrow fs12"></i>
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
  name: "BillingInvoicesTable",
  data() {
    return {
      isBusy:false,
      time3: null,
      filterResultsToggle: false,
      selFilters: [],
      pageOptions: this.$store.state.pageOptions,
      totalRows: 1,
      currentPage: 1,
      perPage: this.$store.state.perPage,
      totalPages: 1,
      prevDisabled: true,
      nextDisabled: false,
      startPage: 1,
      pagesArr: [],
      items:[],
      fields: [
        {
          key: "transactionDate",
          sortable: true,
          thClass: "wsnw",
        },
        {
          key: "invduedate",
          label: "Inv. Due Date",
          sortable: true,
        },
        {
          key: "refNo",
          label: "Invoice No.",
          sortable: true,
          tdClass: "wsnw",
        },
        {
          key: "amount",
          label:"Amount (₹)",
          sortable: true,
        },
        {
          key: "paidAmount",
          label:"Paid Amount (₹)",
          sortable: true,
        },
        {
          key: "tds",
          label: "TDS (₹)",
          sortable: true,
        },
        {
          key: "cn",
          label: "CN (₹)",
          sortable: true,
        },
        {
          key: "dueAmount",
          label:"Due Amount (₹)",
          sortable: true,
        },
        {
          key: "closingDue",
          sortable: true,
          label:"Closing Due (₹)"
        },
        {
          key: "status",
          sortable: true,
          thClass: "px-0 text-center",
          tdClass: "px-0",
        },
        {
          key: "dispute",
          sortable: false,
        },
        {
          key: "download",
          sortable: false,
        }
      ],
    };
  },
  components: {
    DatePicker,
    Multiselect,
  },
  mounted() {
    this.totalRows = this.items.length;
    this.totalPages = Math.ceil(this.totalRows / this.perPage);
    for (var i = 1; i <= this.totalPages; i++) {
      this.pagesArr.push(i);
    }

    this.$root.$on("eventing", () => {
      this.openCancelModal;
    });

    this.getInvoices();
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    // items() {
    //   return this.$store.getters.billingInvoicesItems;
    // },
  },
  methods: {
    async getInvoices() {
      try {
        this.isBusy = true;
        const response = await this.axios.get('/billing/invoices')
        this.items = response.data.invoiceData
        this.items = this.items.reverse()
        this.items.forEach((item) => {
          // status rendering w.r.t status code from backend 
          if (item.dueAmount <= 0) {
            item.status = 'paid'
          } else if(item.dueAmount == item.amount) {
            item.status = 'unpaid'
          } else if(item.dueAmount > 0 && item.dueAmount < item.amount) {
            item.status = 'partial'
          } else {
          }
          // limiting decimal point to 2 places
          item.closingDue = item.closingDue.toFixed(2)
          item.paidAmount = item.paidAmount.toFixed(2)
          item.tdsAmount = item.tdsAmount.toFixed(2)
          item.crnAmount = item.crnAmount.toFixed(2)

          //
          if(item.invoiceDate == null) {
            let t = new Date(item.transactionDate)
            let i = new Date(item.invoiceDate)
            t = t.setDate(t.getDate() + 6)
            item.invoiceDate = new Date(t)
          } else {}

          // change of columns in table w.r.t transaction type
          if (item.type === "Invoice") {
            item["amount_inv"] = this.addZeroes(item.amount.toString());
            item.amount = "-";
          } else {
            item["amount_inv"] = "-";
          }

          item["latestDate"] = false
          let d = new Date(item.transactionDate)
          let n = d.getFullYear();
          if (n > 2018) {
            item["latestDate"] = true
          }
        })

        this.totalRows = this.items.length
        this.setPageNumber();
        this.isBusy = false;
        this.sendData();
      }
      catch(error) {
        this.isBusy = false
        this.popToast("failed","Failed!","Data Fetching Failed!! Please Try again Later");
      }
    },
    sendData() {
      this.$emit('receiveBillingData',this.items.length)
    },
    async downloadToast(ref,type) {
      if (type === 'pdf') {
        const response = await this.axios.get(`/billdownload/${ref}.pdf`)
        this.download(response.data.path, `${ref}.pdf`)
      } else if(type === 'excel') {
        const response = await this.axios.get(`/billdownload/${ref}.xlsx`)
        this.download(response.data.path, `${ref}.xlsx`)
      }
      this.popToast(
        "requested",
        "Download requested",
        "Your download should start now."
      );
    },
    updatePageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
    },
    setPageNumber() {
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
      this.pagesArr = [];
      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }
      this.currentPage = 1;
      this.prevDisabled = true;
      if (this.totalPages == 1) {
        this.nextDisabled = true;
      } else if (this.totalPages > 1) {
        this.nextDisabled = false;
      }
    },
    nextPage() {
      this.currentPage++;
    },
    prevPage() {
      this.currentPage--;
    },
    applyFilter() {
      this.filterResultsToggle = true;
    },
    getFilters(value) {
      this.selFilters = null;
      this.selFilters = value;
      this.filterResultsToggle = true;
    },
    removeFilter(item) {
      this.selFilters.splice(item.id, 1);
    },
    goDispute(ref){
      this.$router.push(`/billing/dispute/${ref}`);
    },
    goExist(ref) {
      this.$router.push(`/billing/getdisputedetails/${ref}`);
    },
    downloadOrders() {
      this.popToast(
        "requested",
        "Download requested",
        "Your download should start now."
      );
      this.filterResultsToggle = false;
    },
        clearFilters() {
      this.filterResultsToggle = false;
      this.selFilters = [];
    }
  },
  watch: {
    currentPage: function() {
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentPage == 1) {
        this.prevDisabled = true;
      }

      if (this.currentPage == this.totalPages) {
        this.nextDisabled = true;
      }
    },
  },
};
</script>

<style type="text/css" scoped>
  .billing-table {
    min-height: unset !important;
    max-height: 370px !important; 
  }
  .pagination-bar {
    left: unset !important;
    right: unset !important;
    width: 100% !important;
    padding: 0rem 0 0.75rem 0 !important;
  }
</style>
