var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative" },
    [
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.filterResultsToggle
          ? _c("div", { staticClass: "select-bar d-flex billing" }, [
              _c(
                "div",
                { staticClass: "container-fluid d-flex align-items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          this.selFilters.length != "0"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-none d-md-flex mr-6 text-gray-600 wsnw"
                                },
                                [_vm._v(" Results for: ")]
                              )
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "d-none d-md-flex result-list" },
                            _vm._l(_vm.selFilters, function(selFilter) {
                              return _c(
                                "li",
                                { key: selFilter.id },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mx-4 my-1",
                                      attrs: { variant: "tag" }
                                    },
                                    [
                                      _c("span", { staticClass: "wsnw" }, [
                                        _vm._v(
                                          " " + _vm._s(selFilter.filter) + " "
                                        )
                                      ]),
                                      _c("i", {
                                        staticClass: "icon-close fs6",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeFilter(
                                              selFilter.id
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          this.selFilters.length != "0"
                            ? _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(this.selFilters.length) +
                                    " Filters selected. "
                                )
                              ])
                            : _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(" No filters selected. ")
                              ]),
                          _c(
                            "b-link",
                            {
                              staticClass: "ml-24 mr-12 vam",
                              on: {
                                click: function($event) {
                                  return _vm.clearFilters()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-gray-900 wsnw" },
                                [_vm._v("Clear filters")]
                              ),
                              _c("i", {
                                staticClass:
                                  "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "wsnw",
                              attrs: { variant: "secondary" },
                              on: { click: _vm.downloadOrders }
                            },
                            [
                              _c("i", { staticClass: "icon-download fs16" }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-none d-md-inline ml-0 ml-md-10"
                                },
                                [_vm._v(" Download Orders ")]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.sidebarFilters",
                                  modifiers: { sidebarFilters: true }
                                }
                              ],
                              staticClass:
                                "text-gray-900 ml-8 d-none d-md-flex",
                              attrs: { variant: "secondary" }
                            },
                            [
                              _c("span", { staticClass: "d-none d-md-flex" }, [
                                _vm._v(" Filters ")
                              ]),
                              _c("i", {
                                staticClass:
                                  "icon-filter text-gray-600 pl-0 pl-md-10"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm.items.length > 0
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "billing-table",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "current-page": _vm.currentPage,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  responsive: "",
                  "sticky-header": "",
                  "no-border-collapse": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "head(status)",
                      fn: function() {
                        return [_c("span", [_vm._v(" Status ")])]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(type)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "value-type" }, [
                            _vm._v(" " + _vm._s(data.item.type) + " ")
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(transaction_date)",
                      fn: function(data) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("date")(data.item.transaction_date))
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(amount_inv)",
                      fn: function(data) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                data.item.amount_inv != "-"
                                  ? "" + data.item.amount_inv
                                  : "-"
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(amount)",
                      fn: function(data) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                data.item.amount != "-"
                                  ? "" + data.item.amount
                                  : "-"
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(closing_due)",
                      fn: function(data) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                data.item.closing_due != ""
                                  ? "" + data.item.closing_due
                                  : "-"
                              )
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              data.item.typesort == "INV" ||
                              data.item.typesort == "INVI"
                                ? [
                                    data.item.buttonLabel == "Dispute"
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary btn-xs",
                                              disabled: data.item.buttonDisable
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goDispute(
                                                  data.item.ref_number
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.buttonLabel) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    data.item.buttonLabel == "Exist"
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "primary btn-xs"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goExist(
                                                  data.item.ref_number
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.buttonLabel) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              [
                                !(
                                  data.item.typesort == "INV" ||
                                  data.item.typesort == "INVI"
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tag",
                                        class: data.item.status
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("capitalize")(
                                                data.item.status
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ],
                            2
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(download)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              (data.item.typesort == "INV" ||
                                data.item.typesort == "INVI") &&
                              !data.item.ref_number.includes("SLR") &&
                              data.item.latestDate
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.right",
                                            modifiers: {
                                              hover: true,
                                              right: true
                                            }
                                          }
                                        ],
                                        attrs: {
                                          size: "xs",
                                          variant:
                                            "icon-transparent-gray-800 px-6",
                                          title: "Download PDF"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadToast(
                                              data.item.ref_number,
                                              "pdf"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-export-pdf fs20"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.right",
                                            modifiers: {
                                              hover: true,
                                              right: true
                                            }
                                          }
                                        ],
                                        attrs: {
                                          size: "xs",
                                          variant:
                                            "icon-transparent-gray-800 px-6",
                                          title: "Download XLS"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadToast(
                                              data.item.ref_number,
                                              "excel"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-export-xls fs20"
                                        })
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3816161241
                )
              })
            ],
            1
          )
        : _c("div", [_vm._m(0)]),
      _c(
        "ul",
        { staticClass: "table-cards d-flex d-md-none" },
        _vm._l(_vm.items, function(item) {
          return _c("li", { key: item.id }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between"
                },
                [
                  _c("span", { staticClass: "d-block fw500 pb-4" }, [
                    _vm._v(" Reference No: " + _vm._s(item.ref_number) + " ")
                  ]),
                  item.typesort == "INV" || item.typesort == "INVI"
                    ? _c(
                        "div",
                        [
                          item.buttonLabel == "Dispute"
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary btn-xs",
                                    disabled: item.buttonDisable
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.goDispute(item.ref_number)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.buttonLabel) + " ")]
                              )
                            : _vm._e(),
                          item.buttonLabel == "Exist"
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary btn-xs" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goExist(item.ref_number)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.buttonLabel) + " ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  !(item.typesort == "INV" || item.typesort == "INVI")
                    ? _c("span", { staticClass: "tag", class: item.status }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("capitalize")(item.status)) + " "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("span", { staticClass: "mb-4" }, [
                _c("strong", [_vm._v("Type:")]),
                _vm._v(" "),
                _c("span", { staticClass: "value-type" }, [
                  _vm._v(_vm._s(item.type))
                ])
              ]),
              _c("span", { staticClass: "mb-4" }, [
                _c("strong", [_vm._v("Channel:")]),
                _vm._v(" " + _vm._s(item.channel) + " ")
              ]),
              _c("span", { staticClass: "mb-4" }, [
                _c("strong", [_vm._v("Inv. Due Date:")]),
                _vm._v(" " + _vm._s(item.inv_due_date) + " ")
              ]),
              _c("span", { staticClass: "carrier-mode mb-4" }, [
                _c("strong", [_vm._v("Transaction date:")]),
                _vm._v(
                  " " + _vm._s(_vm._f("date")(item.transaction_date)) + " "
                )
              ]),
              _c("span", { staticClass: "d-block mb-4" }, [
                _c("strong", [_vm._v("Amount Inv. (₹):")]),
                _vm._v(" " + _vm._s(item.amount_inv) + " ")
              ]),
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("span", { staticClass: "d-block mb-4" }, [
                  _c("strong", [_vm._v("Amount (₹): ")]),
                  _vm._v(" " + _vm._s(item.amount) + " ")
                ]),
                (item.typesort == "INV" || item.typesort == "INVI") &&
                !item.ref_number.includes("SLR") &&
                item.latestDate
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.right",
                                modifiers: { hover: true, right: true }
                              }
                            ],
                            attrs: {
                              size: "xs",
                              variant: "icon-transparent-gray-800 px-6",
                              title: "Download PDF"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadToast(item.ref_number, "pdf")
                              }
                            }
                          },
                          [_c("i", { staticClass: "icon-export-pdf fs20" })]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.right",
                                modifiers: { hover: true, right: true }
                              }
                            ],
                            attrs: {
                              size: "xs",
                              variant: "icon-transparent-gray-800 px-6",
                              title: "Download XLS"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadToast(
                                  item.ref_number,
                                  "excel"
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "icon-export-xls fs20" })]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ])
        }),
        0
      ),
      _vm.items.length > 0
        ? _c("div", { staticClass: "pagination-bar position-relative" }, [
            _c("div", { staticClass: "container-fluid p-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex wsnw align-items-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-block w50px" },
                        [
                          _c("multiselect", {
                            staticClass: "pagination-items__pp",
                            attrs: {
                              searchable: false,
                              "show-labels": false,
                              "allow-empty": false,
                              options: _vm.pageOptions
                            },
                            on: { input: _vm.setPageNumber },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", {
                        staticClass:
                          "pl-8 fs12 lh24 text-gray-600 pagination-items"
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-8 text-right" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-flex ml-auto wsnw align-items-center"
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "fs12 lh24 text-gray-600 pr-14" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.currentPage) +
                              " of " +
                              _vm._s(_vm.totalPages) +
                              " pages "
                          )
                        ]
                      ),
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.prevDisabled,
                                variant: "paginate left"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.prevPage()
                                }
                              }
                            },
                            [_c("i", { staticClass: "icon-back fs12" })]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.nextDisabled,
                                variant: "paginate right"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.nextPage()
                                }
                              }
                            },
                            [_c("i", { staticClass: "icon-arrow fs12" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-120" }, [
      _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
        _c("i", { staticClass: "icon-empty-state text-blue pb-24 fs58" }),
        _c("span", { staticClass: "fs14 fw500 pb-8 text-blue" }, [
          _vm._v(" Data not Available ")
        ]),
        _c("span", { staticClass: "fs12 pb-8 text-gray-600" }, [
          _vm._v(" There is no data to show you right now ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }