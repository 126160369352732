var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-results" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-items-center mw245" },
        [
          _c("i", { staticClass: "icon-empty-state text-blue pb-24 fs46" }),
          _c("span", { staticClass: "fs14 fw500 pb-8 text-blue" }, [
            _vm._v(" Data not Available ")
          ]),
          _c("span", { staticClass: "fs11 text-gray-600 text-center" }, [
            _vm._v(" There is no data to show you right now ! ")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }