<template>
   <div class="card card-sm">
     <div class="card-sm__header">
       <slot name="header"></slot>
     </div>
     <div class="card-sm__content">
             <div class="icon-wrap fs20" :class="this.iconColor">
         <i :class="this.iconClass"></i>
       </div>
       <slot></slot>
     </div>
   </div>
 </template>

 <script>
 export default {
   name: "DashboardCard",
   props: {
     iconColor: String,
     iconClass: String,
   },
 };
 </script>

 <style scoped>
   .card-sm__content {
      width: max-content;
   }
 </style>